import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import Causes from "../../api/cause";
import CauseTabs from "./alltab";

import Logo from "../../images/logo.png";
import Footer from "../../components/footer";

const CauseSinglePage = (props) => {
  const id = props.match.params.id;

  const CauseDetails = Causes.find((item) => item.id === id);

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      {/* <PageTitle
        Image={CauseDetails.Img}
        Title={CauseDetails.cTitle}
        pagesub={"Case Single"}
      /> */}
      <div
        style={{
          height: "auto",
          position: "relative",
          background: "no-repeat center top / contain",
        }}
      >
        <img src={CauseDetails.Img} alt="" style={{ width: "100%" }} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2 className="page-title-heading">{CauseDetails.cTitle}</h2>
        </div>
      </div>
      <div className="wpo-case-details-area section-padding">
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <div className="row">
            <div className="col col-lg-12">
              <div className="wpo-case-details-wrap">
                <div className="wpo-case-details-img">
                  <img src={CauseDetails.cImgSingle} alt="" />
                </div>
                <CauseTabs />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default CauseSinglePage;
