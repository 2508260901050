import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import validation from "./validation";
import Image from "../../images/volunteer-title.webp";

const VolunteerPage = () => {
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    number: "",
    case: "",
  });
  const [errors, setErrors] = useState({});

  function handleChange(e) {
    const newObj = { ...values, [e.target.name]: e.target.value };
    setValues(newObj);
  }

  const showToastMessage = () => {
    toast.success("Message Sent Successfully !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };
  // const SubmitHandler = (e) => {
  //   e.preventDefault();
  // };
  const form = useRef();

  const isEmpty = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const volunteerValue = validation(values);

    if (isEmpty(volunteerValue)) {
      emailjs
        .sendForm(
          "service_7g0x1wh",
          "template_cac0liv",
          form.current,
          "Yi9agXA7pqOTj_syN"
        )
        .then(
          (result) => {
            console.log(result.text);
            showToastMessage("Message Sent Successfully !");

            setValues({
              name: "",
              lastname: "",
              email: "",
              number: "",
              case: "",
            });
          },
          (error) => {
            console.log(error.text);
            showToastMessage("Message Sent Failed !");
          }
        );
    } else {
      setErrors(volunteerValue);
    }
    e.target.reset();
  };

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      {/* <PageTitle
        className="volunteer-page-background"
        pageTitle={"Volunteer"}
        pagesub={"Volunteer"}
      /> */}
      <PageTitle Image={Image} Title="Volunteer" />
      <div className="wpo-case-text-top">
        <div className="case-b-text" style={{ padding: "60px" }}>
          <p>
            At Pardos Conservation we provide dedicated individuals like you
            with the opportunity to make a tangible difference in the
            preservation of our planet's incredible biodiversity. As a wildlife
            conservation volunteer, you'll be an essential part of our global
            efforts to protect and restore ecosystem, save endangered species,
            and combat the threats posed by habitat loss, poaching, and climate
            change. Whether you're an experienced conservationist or just
            starting your journey, there's a place for you here.
          </p>
          <p>
            Joining our team means immersing yourself in the beauty of nature,
            gaining hands-on experience, and working alongside experts who share
            your dedication. Together, we'll forge a path toward a sustainable
            future where wildlife thrives, and the wonders of our natural world
            endure for generations to come.
          </p>
          <p>
            Take the first step towards becoming a wildlife conservation
            volunteer. Together, we can create a brighter future for our
            planet's irreplaceable wildlife.
          </p>
        </div>
      </div>
      <div className="volunteer-wrap">
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="volunteer-item"></div>
              <div className="volunteer-contact-form">
                <h2>Become a Volunteer</h2>
                <ToastContainer />
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="contact-validation-active"
                  id="contact-form-main"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        onChange={handleChange}
                        placeholder="First Name"
                      />
                      {errors.name && (
                        <p style={{ color: "red" }}>{errors.name}</p>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                      <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        id="name"
                        onChange={handleChange}
                        placeholder="Last Name"
                      />
                      {errors.lastname && (
                        <p style={{ color: "red" }}>{errors.lastname}</p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        onChange={handleChange}
                        placeholder="Email"
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email}</p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                      <input
                        type="tel"
                        className="form-control"
                        name="number"
                        id="number"
                        onChange={handleChange}
                        placeholder="Contact Number"
                      />
                      {errors.number && (
                        <p style={{ color: "red" }}>{errors.number}</p>
                      )}
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-in">
                      <label htmlFor="file">Upload Your CV</label>
                      <input
                        id="file"
                        type="file"
                        className="form-control"
                        name="file"
                      />
                      <i className="ti-cloud-up"></i>
                    </div> */}
                    <div className="col-lg-12 col-12 form-group">
                      <textarea
                        className="form-control"
                        name="case"
                        id="note"
                        onChange={handleChange}
                        placeholder="Case Description..."
                      ></textarea>
                      {errors.case && (
                        <p style={{ color: "red" }}>{errors.case}</p>
                      )}
                    </div>
                    <div className="submit-area col-lg-12 col-12">
                      <button type="submit" className="theme-btn submit-btn">
                        Send Message
                      </button>
                      <div id="loader">
                        <i className="ti-reload"></i>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix error-handling-messages">
                    <div id="success">Thank you</div>
                    <div id="error">
                      {" "}
                      Error occurred while sending email. Please try again
                      later.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default VolunteerPage;
