import React from "react";
import { Link } from "react-router-dom";

const CtaSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className={`wpo-cta-area ${props.ctClass}`} id="volunteer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Link onClick={ClickHandler} to="/volunteer">
              <div className="wpo-cta-section"></div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
