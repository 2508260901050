import React from "react";
import { Link } from "react-router-dom";
import abimg from "../../images/about2.jpg";
import abimg2 from "../../images/about3.jpg";
import abimg3 from "../../images/ab-shape-3.png";

const AboutS3 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-about-section-s3 section-padding" id="about2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              <div className="wpo-about-img">
                <img src={abimg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text">
              <h2>
                Wilderness Meets Innovation, A Sustainable Wildlife Future
              </h2>
              <p>
                Our passion for wildlife and the natural world drives our
                unwavering commitment to conservation. Our journey began with a
                simple belief: that every species, every ecosystem, and every
                corner of our planet deserves a chance to thrive.{" "}
              </p>
              <ul>
                <li>To protect and preserve the Karnataka biodiversity.</li>
                <li>
                  Earth's life tapestry: a wonder, inspiration, and vital
                  balance source.
                </li>
                <li>
                  We're committed to safeguarding Earth's intricate web for
                  present and future.
                </li>
              </ul>
              <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">
                More About
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="ab-left-img">
        <img src={abimg2} alt="" />
      </div>
      <div className="ab-right-img">
        <img src={abimg3} alt="" />
      </div>
    </section>
  );
};

export default AboutS3;
