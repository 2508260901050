import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from "../../images/partners/img-1.jpg";
import pimg2 from "../../images/partners/img-2.jpg";
import pimg3 from "../../images/partners/img-3.jpg";
import pimg4 from "../../images/partners/img-4.jpg";
import pimg5 from "../../images/partners/img-5.jpg";
import { FormatAlignCenter } from "@material-ui/icons";
import "../PartnerSection/style.css";

const partners = [
  {
    pImg: pimg1,
  },
  {
    pImg: pimg2,
  },
  // {
  //   pImg:pimg3,
  // },
  // {
  //   pImg:pimg4,
  // },
  // {
  //   pImg:pimg5,
  // },
  // {
  //   pImg:pimg1,
  // },
];

class PartnerSection extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section
        // className={`partners-section section-padding ${this.props.tNone}`}
        className="partnerpage"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="wpo-section-title">
              <h2 className="ourpartner">Our Partners </h2>
              {/* <div className="partnerimage">
                <img src={pimg1} />
                <img src={pimg2} />
              </div> */}
              <div className="wpo-service-single-sub-img">
                {/* <ul>
                  <li> */}
                <img src={pimg1} alt="" className="partnerimg1" />
                {/* </li>
                  <li> */}
                <img src={pimg2} alt="" className="partnerimg2" />
                {/* </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PartnerSection;
