import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/service";
import Logo from "../../images/logo.png";
import Footer from "../../components/footer";

const SeviceSinglePage = (props) => {
  const { id } = useParams();

  const serviceDetails = Services.find((item) => item.id === id);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle Image={serviceDetails.Img} Title={serviceDetails.title} />
      <section className="wpo-service-single-section section-padding">
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-content">
                  <div className="wpo-service-single-content-des">
                    <p>{serviceDetails.description}</p>
                    <p>{serviceDetails.description2}</p>
                    <div className="wpo-service-single-sub-img">
                      <ul>
                        <li>
                          <img
                            className="service_img"
                            src={serviceDetails.simg2}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            className="service_img"
                            src={serviceDetails.simg3}
                            alt=""
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="wpo-solutions-section">
                  <div className="row">
                    {serviceDetails.data.map((service, Sitem) => (
                      <div className="col-lg-4 col-md-6 col-12" key={Sitem}>
                        <div className="wpo-solutions-item">
                          <div className="wpo-solutions-icon">
                            <div className="icon">
                              <img src={service.sImg} alt="" />
                            </div>
                          </div>
                          <div className="wpo-solutions-text">
                            <h2>{service.title}</h2>
                            <p>{service.subdescription}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="wpo-about-text">
                  <h2>Benefits</h2>
                  <ul>
                    <li>{serviceDetails.benefit1}</li>
                    <li>{serviceDetails.benefit2}</li>
                    <li>{serviceDetails.benefit3}</li>
                    <li>{serviceDetails.benefit4}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default SeviceSinglePage;
