import React from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";
import MobilePage from "../MobilePage";
import useWindowSize from "../../useWindowSize";

const App = () => {
  const size = useWindowSize();
  return (
    <>
      {/* {size.width < 640 && <MobilePage />}
      {size.width > 640 && (
        <div className="App" id="scrool">
          <AllRoute />
          <ToastContainer />
        </div>
      )} */}
      <div className="App" id="scrool">
        <AllRoute />
        <ToastContainer />
      </div>
    </>
  );
};

export default App;
