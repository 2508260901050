import React from "react";
import { Input } from "reactstrap";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import contactvalidation from "./contactvalidation";

const ContactForm = () => {
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  function handleChange(e) {
    const newObj1 = { ...values, [e.target.name]: e.target.value };
    setValues(newObj1);
  }

  const showToastMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  // const SubmitHandler = (e) => {
  //   e.preventDefault();
  // };
  const form = useRef();
  const isEmpty = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  };
  const sendEmail = (e) => {
    e.preventDefault();
    const contactValue = contactvalidation(values);

    if (isEmpty(contactValue)) {
      emailjs
        .sendForm(
          "service_7g0x1wh",
          "template_cac0liv",
          form.current,
          "Yi9agXA7pqOTj_syN"
        )
        .then(
          (result) => {
            console.log(result.text);
            showToastMessage("Message Sent Successfully !");

            setValues({
              name: "",
              lastname: "",
              email: "",
              subject: "",
              message: "",
            });
          },
          (error) => {
            console.log(error.text);
            showToastMessage("Message Sent Failed !");
          }
        );
    } else {
      setErrors(contactValue);
    }
    e.target.reset();
  };

  return (
    <Fragment>
      <div className="volunteer-contact-form">
        <ToastContainer />
        <form
          ref={form}
          onSubmit={sendEmail}
          className="contact-validation-active"
          id="contact-form-main"
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                id="name"
                placeholder="First Name"
                onChange={handleChange}
              />
              {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
              <input
                type="text"
                className="form-control"
                name="lastname"
                id="name"
                placeholder="Last Name"
                onChange={handleChange}
              />
              {errors.lastname && (
                <p style={{ color: "red" }}>{errors.lastname}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="Email"
                onChange={handleChange}
              />
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
              <input
                type="tel"
                className="form-control"
                name="number"
                id="number"
                placeholder="Number"
                onChange={handleChange}
              />
              {errors.number && <p style={{ color: "red" }}>{errors.number}</p>}
            </div>
            <div className="col-lg-12">
              <div className="form-field">
                <textarea
                  name="message"
                  placeholder="Message"
                  onChange={handleChange}
                ></textarea>
                {errors.message && (
                  <p style={{ color: "red" }}>{errors.message}</p>
                )}
              </div>
            </div>

            <div className="form-submit">
              <button type="submit" className="theme-btn">
                Send Message
              </button>
            </div>
          </div>
          <div className="clearfix error-handling-messages">
            <div id="success">Thank you</div>
            <div id="error">
              {" "}
              Error occurred while sending email. Please try again later.
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};
export default ContactForm;
