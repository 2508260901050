import React, { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import Navbar from "../../components/Navbar";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";

const TermPage = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <Fragment>
      <Navbar Logo={Logo} />

      <Row>
        <Col sm="12">
          <div className="wpo-case-content">
            <div className="wpo-case-text-top" style={{ margin: 60 }}>
              <div className="case-b-text">
                <h4>Terms of Use</h4>
                <p>Effective Date: 1 st September 2023</p>

                <span>
                  <h6>1. Acceptance of Terms </h6>
                  <p>
                    Welcome to Pardos Conservation Charitable Trust ("the
                    Website"). By accessing or using the Website, you agree to
                    comply with and be bound by these Terms of Use ("Terms"). If
                    you do not agree to these Terms, please do not use the
                    Website.
                  </p>{" "}
                </span>
                <span>
                  <h6>2. Use of the Website </h6>
                  <p>
                    You agree to use the Website for lawful purposes and in a
                    manner consistent with all applicable local, state, and
                    federal laws and regulations.
                  </p>{" "}
                </span>
                <span>
                  <h6>3. Intellectual Property </h6>
                  <p>
                    All content on the Website, including text, graphics, logos,
                    images, audio, and video, is the property of Pardos
                    Conservation Charitable Trust or its content suppliers and
                    is protected by copyright andother intellectual property
                    laws. You may not reproduce, distribute, modify, display, or
                    create derivative works from any content without prior
                    written consent.
                  </p>{" "}
                </span>
                <span>
                  <h6>4. User Content </h6>
                  <p>
                    If you submit content to the Website, you grant Pardos
                    Conservation Charitable Trust a non-exclusive, royalty-free,
                    perpetual, irrevocable, and fully sublicensable right to
                    use, reproduce, modify, adapt, publish, translate, create
                    derivative works from, distribute, and display such content
                    throughout the world in any media.
                  </p>{" "}
                </span>
                <span>
                  <h6>5. Privacy </h6>
                  <p>
                    Your use of the Website is governed by our Privacy Policy,
                    which can be found
                    <Link onClick={ClickHandler} to="/privacy">
                      &nbsp; Privacy Policy
                    </Link>
                    .
                  </p>{" "}
                </span>
                <span>
                  <h6>6. Disclaimers and Limitations of Liability </h6>
                  <p>
                    The Website is provided "as is" and "as available" without
                    warranties of any kind, either expressed or implied,
                    including, but not limited to, the implied warranties of
                    merchantability, fitness for a particular purpose, or
                    non-infringement. Pardos Conservation Charitable Trust does
                    not warrant that the Website will be uninterrupted or
                    error-free, that defects will be corrected, or that the
                    Website is free of viruses or other harmful components.
                    Pardos Conservation Charitable Trust shall not be liable for
                    any direct, indirect, incidental, special, or consequential
                    damages that result from your use of or inability to use the
                    Website.
                  </p>{" "}
                </span>
                <span>
                  <h6>7. Changes to Terms </h6>
                  <p>
                    Pardos Conservation Charitable Trust may revise these Terms
                    at any time by updating this page. You should visit this
                    page periodically to review the current Terms because they
                    are binding on you.
                  </p>{" "}
                </span>
                <span>
                  <h6>8. Termination </h6>
                  <p>
                    Pardos Conservation Charitable Trust may terminate your
                    access to the Website, without cause or notice, which may
                    result in the forfeiture and destruction of all information
                    associated with your account.
                  </p>{" "}
                </span>
                <span>
                  <h6>9. Governing Law </h6>
                  <p>
                    These Terms are governed by and construed in accordance with
                    the laws of India. Any disputes relating to these Terms will
                    be subject to the exclusive jurisdiction of the state and
                    federal courts located within Karnataka, India.
                  </p>{" "}
                </span>
                <span>
                  <h6>10. Contact Us </h6>
                  <p>
                    If you have any questions about these Terms, please contact
                    us at{" "}
                    <a href="mailto:info@pardosconservation.com">
                      &nbsp; info@pardosconservation.com
                    </a>
                  </p>{" "}
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default TermPage;
