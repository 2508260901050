import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Contactpage from "../../components/Contactpage";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";
import Image from "../../images/page-title.webp";

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      {/* <PageTitle pageTitle={"Contact Us"} pagesub={"Contact"} /> */}
      <PageTitle Image={Image} Title="Contact Us" />
      <Contactpage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
