// import React from "react";
// import { Link } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";

// const PageTitle = (props) => {
//   return (
//     <div
//       className={`wpo-breadcumb-area ${props.className}`}
//       style={
//         props.backgroundImage
//           ? { backgroundImage: props.backgroundImage }
//           : null
//       }
//     >
//       <div className="container">
//         <div className="row">
//           <div className="col-12">
//             <div className="wpo-breadcumb-wrap">
//               <h2 style={props.color ? { color: props.color } : null}>
//                 {props.pageTitle}
//               </h2>
//               {/* <ul>
//                                 <li><Link to="/home">Home</Link></li>
//                                 <li><span>{props.pagesub}</span></li>
//                             </ul> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PageTitle;

import React from "react";

const PageTitle = (props) => {
  return (
    <div
      style={{
        // maxHeight: "300px",
        height: "auto",
        position: "relative",
        background: "no-repeat center top / contain",
        // overflow: "hidden",
      }}
    >
      <img src={props.Image} alt="" style={{ width: "100%" }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2 className="page-title-heading">{props.Title}</h2>
      </div>
    </div>
  );
};

export default PageTitle;
