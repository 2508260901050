import React, { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import Navbar from "../../components/Navbar";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";

const PrivacyPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />

      <Row>
        <Col sm="12">
          <div className="wpo-case-content">
            <div className="wpo-case-text-top" style={{ margin: 60 }}>
              <div className="case-b-text">
                <h4>Privacy Policy</h4>

                <p>Effective Date: 1 st September 2023</p>

                <h4>Introduction</h4>

                <p>
                  Pardos Conservation Charitable Trust ("we," "us," or "our") is
                  committed to protecting your privacy and providing a safe
                  online experience. This Privacy Policy explains how we
                  collect, use, and safeguard your personal information. By
                  accessing or using our website, you consent to the practices
                  described in this policy.
                </p>

                <span>
                  <h4>Information We Collect</h4>
                  <h6>Personal Information: </h6>
                  <p>
                    We may collect personal information, such as your name,
                    email address, and contact details when you voluntarily
                    provide it to us through forms or other interactions on our
                    website.
                  </p>{" "}
                </span>

                <span>
                  <h6>Usage Data: </h6>
                  <p>
                    We may automatically collect information about your visit to
                    our website, including your IP address, browser type, and
                    operating system, to analyse usage patterns and improve our
                    services.
                  </p>{" "}
                </span>

                <span>
                  <h6>Cookies: </h6>
                  <p>
                    Our website may use cookies and similar tracking
                    technologies to enhance your browsing experience and gather
                    data about how you use our site. You can manage cookie
                    preferences through your browser settings.
                  </p>{" "}
                </span>

                <h4>How We Use Your Information</h4>
                <span>
                  <h6>
                    We may use your information for various purposes, including:{" "}
                  </h6>
                  <p>Providing and personalizing our services.</p>{" "}
                  <p>
                    Communicating with you about updates, promotions, and news.
                  </p>{" "}
                  <p>Analysing and improving our website and services.</p>{" "}
                  <p>Ensuring the security and integrity of our website.</p>{" "}
                </span>
                <span>
                  <h6>Data Sharing</h6>
                  <p>
                    We do not sell, trade, or rent your personal information to
                    third parties. We may share your information with service
                    providers who assist us in delivering our services, subject
                    to confidentiality agreements.
                  </p>
                </span>

                <span>
                  <h6>Security</h6>
                  <p>
                    We use reasonable security measures to protect your personal
                    information from unauthorized access, disclosure,
                    alteration, or destruction.
                  </p>
                </span>

                <span>
                  <h6>Your Choices</h6>
                  <p>
                    You can opt out of receiving promotional emails from us by
                    following the unsubscribe instructions included in the
                    email. You may also contact us to update or delete your
                    personal information.
                  </p>
                </span>

                <span>
                  <h6>Children's Privacy</h6>
                  <p>
                    Our website is not intended for children under the age of
                    13. We do not knowingly collect personal information from
                    children. If you believe we have inadvertently collected
                    such information, please contact us to request its deletion.
                  </p>
                </span>

                <span>
                  <h6>Changes to this Privacy Policy</h6>
                  <p>
                    We may update this Privacy Policy to reflect changes in our
                    practices or legal requirements. We will notify you of any
                    material changes by posting the updated policy on our
                    website.
                  </p>
                </span>

                <span>
                  <h6>Contact Us</h6>
                  <p>
                    If you have questions or concerns about this Privacy Policy
                    or our data practices, please contact us at
                    <a href="mailto:info@pardosconservation.com">
                      &nbsp; info@pardosconservation.com
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default PrivacyPage;
