import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  closeMenu = () => {
    this.setState({
      isMenuShow: false,
    });
  };

  setIsOpen = (id) => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id,
    });
  };

  render() {
    const { isMenuShow, isOpen } = this.state;

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          <div className="menu-close">
            <div className="clox" onClick={this.menuHandler}>
              <i className="ti-close"></i>
            </div>
          </div>

          <ul className="responsivemenu">
            <li className="menu-item-has-children">
              {/* <Link onClick={this.closeMenu} to="/">
                Home
              </Link> */}
              <a href="/#" onClick={this.closeMenu}>
                Home
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href="/#services2" onClick={this.closeMenu}>
                Services
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href="/#about2" onClick={this.closeMenu}>
                About Us
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href="/#volunteer" onClick={this.closeMenu}>
                Volunteer
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href="/#campaign" onClick={this.closeMenu}>
                Campaign
              </a>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/collaboration">
                Collaboration
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/contact">
                Contact
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/donate">
                Donate Now
              </Link>
            </li>
          </ul>
        </div>

        <div className="showmenu" onClick={this.menuHandler}>
          <button type="button" className="navbar-toggler open-btn">
            <span className="icon-bar first-angle"></span>
            <span className="icon-bar middle-angle"></span>
            <span className="icon-bar last-angle"></span>
          </button>
        </div>
      </div>
    );
  }
}
