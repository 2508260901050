import React, { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import Navbar from "../../components/Navbar";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";

const RefundPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />

      <Row>
        <Col sm="12">
          <div className="wpo-case-content">
            <div className="wpo-case-text-top">
              <div className="case-b-text" style={{ margin: 60 }}>
                <h4>Refund and Cancellation Policy</h4>
                <p>Effective Date: 1 st September 2023</p>
                <h4>Donation Refund Policy</h4>

                <span>
                  <h6>General Policy: </h6>
                  <p>
                    Pardos Conservation Charitable Trust appreciates your
                    charitable donations. Alldonations made to our trust are
                    non-refundable and non-transferable. Once a donation is
                    made, itwill not be refunded, unless required by applicable
                    law.
                  </p>{" "}
                  <p>
                    You will provide correct, and true information while
                    completing the process for online donation. Pardos
                    Conservation Charitable Trust reserves the right to confirm
                    and validate the information and other details provided by
                    you at any point in time. If upon confirmation your details
                    are found not to be true (wholly or partly), Pardos
                    Conservation Charitable Trust has the right in its sole
                    discretion to reject the donation.
                  </p>
                </span>
                <span>
                  <h6>Errors: </h6>
                  <p>
                    If you have made an error in your donation or wish to
                    request a refund due to exceptional circumstances, please
                    contact us at info@pardosconservation.com within 3 business
                    days of the donation. We shall rectify such parameters
                    requested by you whenever possible.
                  </p>{" "}
                  <p>
                    The donor will receive a confirmation email on successful or
                    failed transactions.
                  </p>{" "}
                  <p>
                    No refund or cancellation request for donated amounts will
                    be accepted for online donations, except in cases where a
                    fraud notification is received from our payment gateway
                    service provider. Once a donation is made through our online
                    payment gateway, it is considered final and non-refundable.
                  </p>{" "}
                  <p>
                    If a donation is registered under a regular donation
                    program, the donor may inform us
                    atinfo@pardosconservation.com to stop the donation from the
                    next debit cycle. Pardos Conservation Charitable Trust will
                    make its best efforts to execute the request promptly and
                    will inform the donor about the same.
                  </p>{" "}
                  <p>
                    Only Indian donors are eligible for a Tax exemption
                    certificate under section 80G of the Income Tax Act. These
                    certificates will be sent to the registered email and postal
                    address provided during the online donation process for
                    eligible donors.
                  </p>{" "}
                  <p>
                    In the event that an Indian donor does not receive the 80-G
                    certificate within 45 days from the time of realization of
                    the donation amount, they may claim the donated amount from
                    the Pardos Conservation Charitable Trust. To initiate a
                    claim, please contact us at{" "}
                    <a href="mailto:info@pardosconservation.com">
                      &nbsp; info@pardosconservation.com
                    </a>{" "}
                    We will promptly investigate the issue and take appropriate
                    action to resolve it.
                  </p>{" "}
                </span>

                <h4>Event Registration Cancellation Policy</h4>
                <span>
                  <h6>General Policy: </h6>
                  <p>
                    For events organized by Pardos Conservation Charitable
                    Trust, registration fees are generally non-refundable.
                    However, we may consider requests for refunds or event fee
                    transfers under exceptional circumstances, such as event
                    cancellation.
                  </p>{" "}
                  <p>
                    If you need to request a refund or transfer of event
                    registration fees, please send your request to
                    <a href="mailto:info@pardosconservation.com">
                      &nbsp; info@pardosconservation.com
                    </a>{" "}
                    at least 10 business days prior to the event. We will review
                    your request and, if appropriate, take necessary actions.
                  </p>{" "}
                </span>
                <h4>Contact Us</h4>
                <p>
                  If you have any questions or concerns about our refund and
                  cancellation policy, please contact us at
                  <a href="mailto:info@pardosconservation.com">
                    &nbsp; info@pardosconservation.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default RefundPage;
