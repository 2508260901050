import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo2.png";
import Projects from "../../api/projects";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      {/* style={{ marginLeft: "-95px" }} */}
      <div className="wpo-upper-footer">
        {/* style={{ marginLeft: "-95px" }} */}
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="blog" />
                </div>
                <p>Join Pardos Conservation</p>
                <p style={{ lineHeight: "20px" }}>
                  <i>
                    Empowering Conservation Heroes with Your Support. Together,
                    We Save Wildlife and Our Planet.
                  </i>
                </p>
                {/* <ul>
                  <li>
                    <Link to="/">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="ti-google"></i>
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">{/* <h3>Services </h3> */}</div>
                <ul>
                  <li className="menu-item-has-children">
                    <a href="/#services2">Services</a>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="/#about2">About Us</a>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="/#volunteer">Volunteer</a>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="/#campaign">Campaign</a>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">{/* <h3>Contact </h3> */}</div>
                <div className="contact-ft">
                  <p>
                    Would you have any enquiries.Please feel free to contact us
                  </p>
                  <ul>
                    <li>
                      <i className="fi flaticon-mail"></i>
                      <a
                        href="mailto:info@pardosconservation.com"
                        style={{ color: "white" }}
                      >
                        info@pardosconservation.com
                      </a>
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i>+919663316767
                    </li>
                    <li>
                      <i className="fi flaticon-location"></i>Mysuru, Karnataka
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              {/* <div className="widget instagram">
                <div className="widget-title">
                  <h3>Projects</h3>
                </div>
                <ul className="d-flex">
                  {Projects.slice(0, 6).map((project, pitem) => (
                    <li className="grid" key={pitem}>
                      <div className="img-holder">
                        <Link
                          onClick={ClickHandler}
                          to={`/project-single/${project.id}`}
                        >
                          <img src={project.projectImg} alt="" />
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div> */}
              <div className="widget link-widget">
                <div className="widget-title">{/* <h3>Services </h3> */}</div>
                <ul>
                  <li className="menu-item-has-children">
                    <Link onClick={ClickHandler} to="/privacy">
                      Privacy Policy
                    </Link>

                    {/* <a href="/privacy">Privacy Policy </a> */}
                  </li>
                  <li className="menu-item-has-children">
                    <Link onClick={ClickHandler} to="/term">
                      Terms of Use
                    </Link>
                    {/* <a href="/term">Terms of Use</a> */}
                  </li>
                  <li className="menu-item-has-children">
                    <Link onClick={ClickHandler} to="/refund">
                      Refund Policy
                    </Link>
                    {/* <a href="/refund">Refund Policy</a> */}
                  </li>
                  {/* <li className="menu-item-has-children">
                    <a href="/#campaign">Campaign</a>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {/* {" "}
                &copy; 2021 charitio Theme. Design By{" "}
                <Link to="/index">wpOcean</Link>. All Rights Reserved. */}
                Pardos Conservation Charitable Trust, 2023. All Rights Reserved.
                This site is maintained by Stellarbyte.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
