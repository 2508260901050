import cimg1 from "../images/campaign/1.jpg";
import cimg2 from "../images/campaign/2.jpg";
import cimg3 from "../images/campaign/3.jpg";

import eimg1 from "../images/campaign/4.jpg";
import eimg2 from "../images/campaign/5.jpg";
import eimg3 from "../images/campaign/6.jpg";

import ocimg1 from "../images/campaign/7.jpg";
import ocimg2 from "../images/campaign/8.jpg";
import ocimg3 from "../images/campaign/9.jpg";

import wpimg1 from "../images/campaign/10.jpg";
import wpimg2 from "../images/campaign/11.jpg";
import wpimg3 from "../images/campaign/12.jpg";

import cimgSingle from "../images/cause-single/1.jpg";
import cimgSingle2 from "../images/cause-single/2.jpg";
import cimgSingle3 from "../images/cause-single/3.jpg";

import eimgSingle from "../images/cause-single/4.jpg";
import eimgSingle2 from "../images/cause-single/5.jpg";
import eimgSingle3 from "../images/cause-single/6.jpg";

import ocimgSingle from "../images/cause-single/7.jpg";
import ocimgSingle2 from "../images/cause-single/8.jpg";
import ocimgSingle3 from "../images/cause-single/9.jpg";

import wpimgSingle from "../images/cause-single/10.jpg";
import wpimgSingle2 from "../images/cause-single/11.jpg";
import wpimgSingle3 from "../images/cause-single/12.jpg";

import img1 from "../images/leopard-title.webp";
import img2 from "../images/tree-title.webp";
import img3 from "../images/water-title.webp";

import author from "../images/campaign/1.png";

const Causes = [
  {
    id: "1",
    cImg: cimg1,
    cImgSingle: cimgSingle,
    process: "65",
    thumb: "Education",
    cTitle: "Help To Build a Secure Life Of a Poor Children.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    id: "2",
    cImg: cimg2,
    cImgSingle: cimgSingle2,
    process: "75",
    thumb: "Food",
    cTitle: "Ensure Clean Water To The African Kids.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    id: "3",
    cImg: cimg3,
    cImgSingle: cimgSingle3,
    process: "85",
    thumb: "Medicine",
    cTitle: "Your Help Can Save A Human Life From Death.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    backgroundImageClassName: "leopard",
    id: "4",
    Img: img1,
    cImg: eimg1,
    cImgSingle: eimgSingle,
    process: "95",
    thumb: "Elephant",
    cTitle: "Leopard Census",
    subTitle: "Why Leopard Census Matters",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
    para1:
      "Leopards, with their stunning coats and enigmatic presence, are vital to our  planet’s biodiversity. Yet, these elusive creatures face mounting challenges in the wild. A Comprehensive leopard census is a crucial step in understanding and  conserving these iconic predators.",
    para2:
      "We believe that through accurate data collection, we can pave the way for effective conservation strategies that ensure leopards thrive for generations to come.",
    para3:
      "In our quest to conserve and protect leopard populations, we've embraced cutting-edge AI technology as a game-changer in the field of wildlife census.",
    para4:
      "Every contribution counts in our mission to safeguard these incredible big cats.",
    para5:
      "Join us in making a difference, together we can secure a future where leopards roam freely in healthy and thriving ecosystem.",
    subheading:
      "Share our campaign and educate others about the importance of leopard conservation.",
    list: [
      "We utilize AI-equipped camera traps to accurately estimate leopard populations via unique coat pattern recognition.",
      "AI data analysis swiftly identifies leopards, behaviours, movements, and interactions.",
      "Real-time monitoring alerts researchers to leopard presence for swift response",
      "The technology detects subtle leopard behaviour patterns, aiding in mating and territorial identification.",
      "Our approach minimizes census interference, allowing leopards to exhibit natural behaviours undisturbed",
      "AI's precision reduces error margins for reliable, scientifically robust population estimates",
    ],
  },
  {
    backgroundImageClassName: "tiger",
    id: "5",
    Img: img2,
    cImg: eimg2,
    cImgSingle: eimgSingle2,
    process: "55",
    thumb: "Tiger",
    cTitle: "Planting of Trees",
    subTitle: "A Call to Action: Forest Restoration for a Sustainable World",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
    para1:
      "We believe in the power of nature's revival and the profound impact it has on our planet's health.",
    para2:
      "Forests are more than just a collection of trees; they're the lungs of our planet,  purifying the air we breathe and providing a home for countless species. But they are under threat like never before. Deforestation, pollution, and climate change are taking their toll, and it's our responsibility to act.",
    para3:
      "Our mission is simple and heartfelt: to mend our planet, tree by tree. We're wholeheartedly dedicated to the revival and safeguarding of our forests, the very heartbeats of our ecosystem. When you actively join the Forest Restoration Campaign, you play an essential role in this extraordinary voyage toward a world that's greener and more sustainable.",
    para4:
      "By supporting our cause, you're not just planting trees; you're nurturing hope,  restoring balance, and safeguarding the future. Together, we can create a world where forests thrive, ecosystem flourish, and our planet heals.",
    para7:
      "Wildlife forest restoration fosters biodiversity by recreating natural habitats,  safeguarding endangered species, supporting ecosystem resilience, mitigating climate change, and promoting eco-tourism, benefiting both wildlife and local  communities while conserving our planet's natural heritage.",
    subheading: "Together We Thrive: Reviving Our Wilderness",
    list: [
      "We plant trees in areas ravaged by deforestation, helping ecosystem  recover and thrive.",
      "We restore damaged habitats, creating safe havens for wildlife to flourish.",
      "Forests are our allies in fighting climate change. We protect and grow them to capture and store carbon.",
      "We engage local communities, creating sustainable livelihoods and  fostering a sense of stewardship.",
      "Develop a sustainable management plan for ongoing forest health and growth.",
      "Choose native tree species adapted to the local environment.",
    ],
  },
  {
    backgroundImageClassName: "water",
    id: "6",
    Img: img3,
    cImg: eimg3,
    cImgSingle: eimgSingle3,
    process: "35",
    thumb: "WildLife",
    cTitle: "Creating Waterbodies",
    subTitle: "Reviving Rivers: Water Restoration in Wildlife Forests",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
    para1:
      "In our Water Restoration in Wildlife Forests initiative, we recognize the intrinsic  link between flourishing forests and the health of our water ecosystem. Our  mission is to restore this crucial balance, ensuring that vibrant forests translate  to clean and sustainable water sources for both nature and communities.",
    para2:
      "Forests are nature's water purification systems, filtering and storing water for  countless communities downstream. However, deforestation and degradation  disrupt this crucial cycle, compromising water quality and access. Our campaign focuses on reversing this trend.",
    para3:
      "Our endeavour focuses on reestablishing thriving forest ecosystem that, in turn,safeguard the purity and accessibility of water sources. Through reforestation, watershed protection, and community collaboration, we aim to rekindle the connection between forests and water, fostering a healthier planet for generations to come.",
    para4:
      "By joining hands with us, you become a guardian of our forests and water, nurturing a future where vibrant forests equate to pure, sustainable water sources for every living being on our precious planet",

    subheading: "Why Water Restoration Matters",
    list: [
      "Wildlife forest water restoration serves as a natural filter, delivering clean, safe water to both wildlife and downstream communities by reducing sedimentation and filtering pollutants.",
      "Riparian forests stabilize soil, curbing erosion, averting sediment runoff,  and preserving water quality and aquatic habitats",
      "Water restoration in wildlife forests ensures clean water availability,  supporting sustainable livelihoods, agriculture, and recreation, fostering harmony between communities and nature.",
      "Conduct regular water quality testing to monitor pollution levels and ensure the health of aquatic ecosystem.",
      "Involve local communities in water restoration efforts and encourage responsible water use.",
      "Restore and protect wetlands to serve as natural filters and wildlife habitats.",
    ],
  },
  {
    id: "7",
    cImg: ocimg1,
    cImgSingle: ocimgSingle,
    process: "95",
    thumb: "Ocean",
    cTitle: "Ensure a Secure Life Of The Ocean Creature.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    id: "8",
    cImg: ocimg2,
    cImgSingle: ocimgSingle2,
    process: "55",
    thumb: "Cleaning",
    cTitle: "The oceans deserve our respect and care.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    id: "9",
    cImg: ocimg3,
    cImgSingle: ocimgSingle3,
    process: "35",
    thumb: "Ocean",
    cTitle: "Help Us To Stop Illigal Catching of Fish.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    id: "10",
    cImg: wpimg1,
    cImgSingle: wpimgSingle,
    process: "85",
    thumb: "Vaccin",
    cTitle: "We are Giving Vaccin in All Over The World.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    id: "11",
    cImg: wpimg2,
    cImgSingle: wpimgSingle2,
    process: "75",
    thumb: "Medical",
    cTitle: "Ensuring Physical Treatment in Many Countyies.",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
  {
    id: "12",
    cImg: wpimg3,
    cImgSingle: wpimgSingle3,
    process: "95",
    thumb: "Corona",
    cTitle: "Cullecting Sample and Testing in Laboratory",
    Goal: "3,000.00",
    Raised: "2,000.00",
    authorImg: author,
    authorName: "Laura Faiary",
  },
];

export default Causes;
