import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/service";
import "../../sass/custom-bootstrap.css";

const Service2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-features-section-s3 section-padding" id="services2">
      <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <h2>Our Services</h2>
              <p>
                Innovative technologies, from satellite tracking and data
                analytics to AI-driven ecological modelling, are revolutionizing
                the way we study and protect wildlife conservation.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {Services.slice(4, 7).map((service, sitem) => (
            <div className="col col-xl-4 col-lg-6 col-sm-6 col-12" key={sitem}>
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    {/* <i className={`fi  ${service.fIcon1}`}></i> */}
                    <img src={service.sImg} alt="" />
                  </div>
                </div>
                <div className="wpo-features-text">
                  <h2>
                    <Link
                      onClick={ClickHandler}
                      to={`/service-single/${service.id}`}
                    >
                      {service.title}
                    </Link>
                  </h2>
                  <p>{service.homepagedescription}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service2;
