import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Homepage3 from "../HomePage3";
import AboutPage from "../AboutPage";
import SeviceSinglePage from "../SeviceSinglePage";
import CauseSinglePage from "../CauseSinglePage";
import ContactPage from "../ContactPage";
import ErrorPage from "../ErrorPage";
import DonatePage from "../DonatePage";
import VolunteerPage from "../VolunteerPage";
import CollaborationPage from "../CollaborationPage";
import LoginPage from "../LoginPage";
import SignUpPage from "../SignUpPage";
import ForgotPassword from "../ForgotPassword";
import RefundPage from "../RefundPage";
import TermPage from "../TermPage";
import PrivacyPage from "../PrivacyPage";

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage3} />
          <Route path="/home" component={Homepage3} />
          <Route path="/about" component={AboutPage} />
          <Route path="/service-single/:id" component={SeviceSinglePage} />
          <Route path="/cause-single/:id" component={CauseSinglePage} />
          <Route path="/donate" component={DonatePage} />
          <Route path="/volunteer" component={VolunteerPage} />
          <Route path="/collaboration" component={CollaborationPage} />
          <Route path="/refund" component={RefundPage} />
          <Route path="/term" component={TermPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/404" component={ErrorPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={SignUpPage} />
          <Route path="/forgot-password" component={ForgotPassword} />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
