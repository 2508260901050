import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";
import Image from "../../images/donate-title.webp";
// import pimg from "../../images/checkout/img-1.png";
// import pimg2 from "../../images/checkout/img-2.png";
// import pimg3 from "../../images/checkout/img-3.png";
// import pimg4 from "../../images/checkout/img-4.png";
// import { red } from "@material-ui/core/colors";
import Razorpay from "../Razorpay";
import "./styles.scss";

const DonatePage = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      {/* <PageTitle
        className="donate-page-background"
        pageTitle={"Donate"}
        pagesub={"Donate"}
        color={"#846A3C"}
      /> */}
      <PageTitle Image={Image} Title="Donate" />
      <div className="wpo-donation-page-area section-padding">
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <div className="donation-content">
            <p>
              At Pardos Conservation we protect and preserve the planet's most
              precious and vulnerable species and their habitat. We believe that
              every creature, from the majestic Leopard to the smallest
              pollinator, plays a vital role in maintaining the delicate balance
              of our ecosystem. However, the challenges facing wildlife today
              are immense – habitat loss, poaching, and climate change threaten
              leopard species close to extinction.
            </p>
            <p>
              By contributing to our wildlife conservation efforts, you become a
              crucial part of this mission. Your generous donation will directly
              support our projects aimed at safeguarding these magnificent
              creatures and their habitats. Whether it's funding research to
              better understand their needs, supporting human-wildlife
              conflicts, or creating protected areas, your contribution will
              make a significant difference.
            </p>
            <p>
              Together, we can ensure that future generations inherit a world
              where the magnificent tapestry of wildlife continues to thrive.
              Your donation is a powerful statement of your commitment to
              preserving our planet's natural heritage. Thank you for joining us
              in this important endeavour. Together, we can make a lasting
              impact on wildlife conservation.
            </p>
            <div>
              <h4>
                Donation To Pardos Conservation Charitable Trust (Donations only
                from India)
              </h4>
              <p>
                Pardos Conservation Charitable Trust is a registered Non-Profit
                Charitable Organisation in Karnataka, India.
              </p>
              <p>
                Donations in India are exempt from Income Tax under Section 80G
                of the Income Tax Act, 1961
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="wpo-donate-header">
                <h2>Make A Donation</h2>
              </div>
            </div>
          </div>
          <div className="payment_container">
            <div className="payment_button">
              <Razorpay />
            </div>
          </div>

          {/* <div>
            <h4>Donate By Bank Transfer</h4>
            <p>
              {" "}
              <b>BANK NAME: </b>State Bank of India
            </p>
            <p>
              <b>CURRENT ACCOUNT NO: </b>42020790626
            </p>
            <p>
              <b>IFSC CODE:  </b>SBIN0040441
            </p>
            <p>
              <b>BENEFICIARY NAME:   </b>Pardos Conservation Charitable Trust
            </p>
            <p>
              <b>ADDRESS:  </b>NO 790/C 13TH MAIN, T K LAYOUT, MYSURU, Karnataka
              – 570009 
            </p>
            <p>
              For additional information contact accounts@{" "}
              <a
                href="mailto:info@pardosconservation.com"
                style={{ color: "#4f555a", textDecoration: "underline" }}
              >
                pardosconservation.com
              </a>
            </p>
          </div> */}
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default DonatePage;
