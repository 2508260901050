import React, { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";
import Image from "../../images/collab-title.jpg";

const CollaborationPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle Image={Image} Title="Collaboration" />

      <Row>
        <Col sm="12">
          <div className="wpo-donation-page-area section-padding">
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <div className="donation-content">
                <h4>Corporate Partnership for Wildlife Conservation</h4>
                <p>
                  At Pardos Conservation Charitable Trust, we believe that
                  businesses play a vital role in protecting our planet's
                  biodiversity. Join us in making a meaningful impact on
                  wildlife and ecosystem through corporate partnership.
                </p>
                <h4>Why Partner with Us?</h4>
                <p>
                  Environmental Stewardship: Demonstrate your commitment to
                  environmental responsibility and sustainability by aligning
                  your brand with wildlife conservation.
                </p>
                <span>
                  <h6>Positive Brand Image: </h6>
                  <p>
                    Enhance your corporate reputation by supporting a cause that
                    resonates with customers, employees, and stakeholders.
                  </p>{" "}
                </span>
                <span>
                  <h6>Employee Engagement: </h6>
                  <p>
                    Engage your employees in meaningful corporate social
                    responsibility (CSR) activities, fostering teamwork and a
                    sense of purpose.
                  </p>{" "}
                </span>
                <span>
                  <h6>Wildlife Protection: </h6>
                  <p>
                    Your partnership directly contributes to the protection of
                    endangered species and the preservation of their natural
                    habitats.
                  </p>{" "}
                </span>
                <h4>Ways to Partner</h4>
                <span>
                  <h6>Donations: </h6>
                  <p>
                    Contribute financially to our conservation efforts, directly
                    impacting the protection of wildlife and ecosystem.
                  </p>{" "}
                </span>
                <span>
                  <h6>Sponsorship: </h6>
                  <p>
                    Sponsor specific conservation projects, events, or
                    educational programs to showcase your commitment to wildlife
                    preservation.
                  </p>{" "}
                </span>
                <span>
                  <h6>Employee Engagement: </h6>
                  <p>
                    Involve your employees in volunteer activities, fundraising
                    campaigns, or awareness initiatives.
                  </p>{" "}
                </span>
                <span>
                  <h6>Cause Marketing: </h6>
                  <p>
                    Collaborate on cause-related marketing campaigns that
                    highlight your support for wildlife conservation.
                  </p>{" "}
                </span>
                <h4>Recognition and Benefits</h4>
                <span>
                  <h6>Logo Visibility: </h6>
                  <p>
                    Gain visibility through our website, social media, and
                    promotional materials, showcasing your partnership.
                  </p>{" "}
                </span>
                <span>
                  <h6>Customized Partnerships: </h6>
                  <p>
                    Tailor your partnership to meet your corporate social
                    responsibility goals and maximize impact.
                  </p>{" "}
                </span>
                <span>
                  <h6>Impact Reports: </h6>
                  <p>
                    Receive regular reports on the progress and impact of your
                    contributions.
                  </p>{" "}
                </span>
                <h4>Join Us in Making a Difference</h4>
                <p>
                  To explore corporate partnership opportunities and discuss how
                  your business can contribute to wildlife conservation
                </p>
                <p>
                  {" "}
                  Please contact us at partnerships@
                  <a
                    href="mailto:pardosconservation.com"
                    style={{ color: "#4f555a", textDecoration: "underline" }}
                  >
                    pardosconservation.com
                  </a>{" "}
                </p>
                <p>
                  <i>
                    {" "}
                    Together, we can create a sustainable future for our
                    planet's rich tapestry of life.
                  </i>
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default CollaborationPage;
