import himg1 from "../images/service-single/1.jpg";
import himg2 from "../images/service-single/2.jpg";
import himg3 from "../images/service-single/3.jpg";
import cimg from "../images/service-single/4.jpg";
import mimg from "../images/service-single/5.jpg";
import eimg from "../images/service-single/6.jpg";
import seimg from "../images/service-single/7.jpg";
import ptimg from "../images/service-single/8.jpg";
import rhimg from "../images/service-single/9.jpg";

import wimg1 from "../images/service-single/w1.jpg";
import wimg2 from "../images/service-single/w2.jpg";

import ocimg from "../images/service-single/10.jpg";
import ocimg2 from "../images/service-single/11.jpg";
import ocimg3 from "../images/service-single/12.jpg";
import ocimg4 from "../images/service-single/13.jpg";
import ocimg5 from "../images/service-single/14.jpg";

import wpimg1 from "../images/service-single/15.jpg";
import wpimg2 from "../images/service-single/16.jpg";
import wpimg3 from "../images/service-single/17.jpg";

import wpimg4 from "../images/service-single/wp1.jpg";
import wpimg5 from "../images/service-single/wp2.jpg";

import ntimg1 from "../images/service-single/18.jpg";
import ntimg2 from "../images/service-single/20.jpg";
import ntimg3 from "../images/service-single/19.jpg";

import ntimg4 from "../images/service-single/nt1.jpg";
import ntimg5 from "../images/service-single/nt2.jpg";

import picon1 from "../images/icon/1.png";
import picon2 from "../images/icon/2.png";
import picon3 from "../images/icon/3.png";
import simg1 from "../images/service-single/sicon1.svg";
import simg2 from "../images/service-single/sicon2.svg";
import simg3 from "../images/service-single/sicon3.svg";
import simg4 from "../images/service-single/sicon4.svg";
import simg5 from "../images/service-single/sicon5.svg";
import simg6 from "../images/service-single/sicon6.svg";
import simg7 from "../images/service-single/sicon7.svg";
import simg8 from "../images/service-single/scion8.svg";
import simg9 from "../images/service-single/sicon9.svg";
import simg10 from "../images/service-single/sicon10.svg";
import simg11 from "../images/service-single/sicon11.svg";
import simg12 from "../images/service-single/sicon12.svg";

import img1 from "../images/wildlife-title.png";
import img2 from "../images/forest-title.png";
import img3 from "../images/technology-title.png";

const Services = [
  {
    id: "1",
    backgroundImageClassName: "wildlife",
    sImg: simg4,

    title: "Wildlife Census",
    description:
      "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
    subdescription:
      "Systematic animal counting, Assessing populations, Guiding conservation, and Managing ecosystem effectively.",

    simg1: himg1,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "2",
    backgroundImageClassName: "wildlife",
    sImg: simg5,
    title: "Wildlife Tracking & Research",
    description:
      "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
    simg1: cimg,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "3",
    backgroundImageClassName: "wildlife",
    sImg: simg6,
    title: "Anti Poaching Measures",
    description:
      "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
    simg1: mimg,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "4",
    fIcon1: "flaticon-graduation-cap",
    title: "Child Education",
    description:
      "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
    simg1: eimg,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "5",
    Img: img1,
    backgroundImageClassName: "wildlife",
    sImg: simg1,
    fIcon1: "flaticon-tiger",
    title: "Wildlife Conservation ",
    description:
      "Wildlife has cultural and aesthetic significance, enriching our lives. Conserving  wildlife safeguards our own future, promoting a sustainable world where nature  and humanity thrive together.",
    homepagedescription:
      "Preserving Earth's biodiversity through habitat protection, anti-poaching efforts, research, and fostering harmony between humans and nature.",
    description2:
      "Conserving wildlife is extremely important as it helps safeguard species and their natural habitats preserves the diversity of life and ensures a future for our ecosystem.",
    benefit1:
      "Ensures genetic diversity, which is vital for species resilience and adaptation.",
    benefit2:
      "Regulates climate by storing carbon and influencing weather patterns.",
    benefit3:
      "Sustains predator-prey relationships that help regulate wildlife populations.",
    benefit4:
      "Protecting wildlife is an ethical duty to safeguard Earth's natural heritage.",

    simg1: seimg,
    simg2: wimg1,
    simg3: wimg2,
    data: [
      {
        id: "1",
        backgroundImageClassName: "wildlife",
        sImg: simg4,
        title: "Wildlife Census",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "Systematic animal counting, Assessing populations, Guiding conservation, and Managing ecosystem effectively.",
        simg1: himg1,
        simg2: himg2,
        simg3: himg3,
      },
      {
        id: "2",
        backgroundImageClassName: "wildlife",
        sImg: simg5,
        title: "Wildlife Tracking & Research",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "Advanced tech monitors Animal behaviour, Informs conservation, Guide policy, and Protects species",
        simg1: cimg,
        simg2: himg2,
        simg3: himg3,
      },
      {
        id: "3",
        backgroundImageClassName: "wildlife",
        sImg: simg6,
        title: "Anti Poaching Measures",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "Tech combats poaching: Drones, Cameras, GPS, Acoustics, Satellites, AI, Forensics, Communication, Infrared, and Conservation Drones.",
        simg1: mimg,
        simg2: himg2,
        simg3: himg3,
      },
    ],
  },
  {
    id: "6",
    Img: img2,
    backgroundImageClassName: "forest",
    sImg: simg2,
    fIcon1: "flaticon-forest",
    title: "Forest Restoration",
    homepagedescription:
      "Reviving degraded forests by planting native species, controlling invasive plants, and preserving vital ecological functions.",
    description:
      "Forests are life's fabric, vital for biodiversity, ecosystem health, and combating climate change.",
    description2:
      "By restoring forests, we can combat the adverse effects of deforestation, which  include habitat loss, biodiversity decline, and increased CO2 emissions. Restored forests can sequester carbon, mitigate climate change, reduce soil erosion, protect watersheds, and provide essential habitats for a wide range of wildlife.",
    benefit1:
      "Forests, through photosynthesis, sequester carbon and mitigate climate change,  making their restoration vital.",
    benefit2:
      "Trees and forests, as natural filters, enhance air and water quality through  restoration, reducing pollution and sedimentation in water bodies.",
    benefit3:
      "Restoring forests with extensive root systems stabilizes soil and prevents  erosion, reducing landslide risks.",
    benefit4:
      "Restored forests support diverse, often threatened species as their ecosystem  regain health.",

    simg1: ptimg,
    simg2: ntimg4,
    simg3: ntimg5,
    data: [
      {
        id: "1",
        backgroundImageClassName: "forest",
        sImg: simg7,
        title: "Planting",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "Cultivating native plants to restore habitats, support wildlife, and conserve biodiversity sustainably.",
        simg1: himg1,
        simg2: himg2,
        simg3: himg3,
      },
      {
        id: "2",
        backgroundImageClassName: "forest",
        sImg: simg8,
        title: "Water Regulation",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "Balancing water flow in forested habitats to support wildlife and maintain ecosystem health.",
        simg1: cimg,
        simg2: himg2,
        simg3: himg3,
      },
      {
        id: "3",
        backgroundImageClassName: "forest",
        sImg: simg9,
        title: "Habitat Restoration",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "Habitat restoration revitalizes ecosystem by removing invasives, reintroducing natives, and enhancing biodiversity",
        simg1: mimg,
        simg2: himg2,
        simg3: himg3,
      },
    ],
  },
  {
    id: "7",
    Img: img3,
    backgroundImageClassName: "technology",
    sImg: simg3,
    title: "Technology & Research",
    homepagedescription:
      "Technology and Research are integral for evidence-based, efficient, and  innovative approaches to protect and conserve Earth's diverse wildlife.",
    description:
      "The intersection of technology and research not only enhances our  understanding of wildlife but also provides the tools and knowledge necessary to protect and conserve these invaluable natural assets for future generations.",
    // description2:
    //   "By restoring forests, we can combat the adverse effects of deforestation, which  include habitat loss, biodiversity decline, and increased carbon dioxide emissions. Restored forests can sequester carbon, mitigate climate change, reduce soil erosion, protect watersheds, and provide essential habitats for a wide range of wildlife.",
    benefit1:
      "AI-powered algorithms assist in species identification and tracking, even in  remote or challenging environments.",
    benefit2:
      "Technology enables efficient and precise data collection on species populations, behaviours, and habitat conditions.",
    benefit3:
      "Technology supports behavioural studies, disease monitoring, and genetics    research.",
    benefit4:
      "Technology streamlines conservation efforts, making them more cost-effective  and scalable.",

    simg1: rhimg,
    simg2: wpimg4,
    simg3: wpimg5,
    data: [
      {
        id: "1",
        backgroundImageClassName: "technology",
        sImg: simg10,
        title: "Remote Sensing & Monitoring",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "Remote sensing and monitoring aid wildlife conservation by tracking, studying, and protecting species.",
        simg1: himg1,
        simg2: himg2,
        simg3: himg3,
      },
      {
        id: "2",
        backgroundImageClassName: "technology",
        sImg: simg11,
        title: "Artificial Intelligence",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "AI helps in predicting wildlife movements and habitat preferences, facilitating proactive conservation measures",
        simg1: cimg,
        simg2: himg2,
        simg3: himg3,
      },
      {
        id: "3",
        backgroundImageClassName: "technology",
        sImg: simg12,
        title: "GIS & Data Analysis",
        description:
          "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
        subdescription:
          "GIS and data analysis support wildlife conservation by mapping habitats, tracking species, and guiding conservation efforts.",
        simg1: mimg,
        simg2: himg2,
        simg3: himg3,
      },
    ],
  },
  {
    id: "8",
    fIcon1: "flaticon-dolphin",
    title: "Ocean Protection",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ocimg,
    simg2: ocimg4,
    simg3: ocimg5,
  },
  {
    id: "9",
    fIcon1: "flaticon-whale",
    title: "Saving Whale",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ocimg2,
    simg2: ocimg4,
    simg3: ocimg5,
  },
  {
    id: "10",
    fIcon1: "flaticon-fish",
    title: "Reducing Hunting",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ocimg3,
    simg2: ocimg4,
    simg3: ocimg5,
  },
  {
    id: "11",
    fIcon1: picon1,
    title: "Wash Your Hands",
    description: "Lorem ipsum dolor sit amet econsectetur adipiscin.",
    simg1: wpimg1,
    simg2: wpimg4,
    simg3: wpimg5,
  },
  {
    id: "12",
    fIcon1: picon2,
    title: "Wear Musk",
    description: "Lorem ipsum dolor sit amet econsectetur adipiscin.",
    simg1: wpimg2,
    simg2: wpimg4,
    simg3: wpimg5,
  },
  {
    id: "13",
    fIcon1: picon3,
    title: "Avoid Shakehand",
    description: "Lorem ipsum dolor sit amet econsectetur adipiscin.",
    simg1: wpimg3,
    simg2: wpimg4,
    simg3: wpimg5,
  },
  {
    id: "14",
    fIcon1: "flaticon-forest",
    title: "Save Forest",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ntimg3,
    simg2: ntimg4,
    simg3: ntimg5,
  },
  {
    id: "15",
    fIcon1: "flaticon-ecology",
    title: "Recyling Process",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ntimg1,
    simg2: ntimg4,
    simg3: ntimg5,
  },
  {
    id: "16",
    fIcon1: "flaticon-eco-light",
    title: "Green Energy",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ntimg2,
    simg2: ntimg4,
    simg3: ntimg5,
  },
];
export default Services;
