export default function validation(values) {
  const errors = {};
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/;
  const number_pattern = /^\+?[0-9-]+$/;

  if (values.name === "") {
    errors.name = "Name is Required";
  }
  if (values.lastname === "") {
    errors.lastname = "Last Name is Required";
  }
  if (values.email === "") {
    errors.email = "email is Required";
  }
  if (!email_pattern.test(values.email)) {
    errors.email = "Email is not correct";
  }
  if (values.number === "") {
    errors.number = "Number is Required";
  }
  if (!number_pattern.test(values.number)) {
    errors.number = "Number is not correct";
  }
  if (values.case === "") {
    errors.case = "Case Name is Required";
  }
  return errors;
}
