import React from "react";
import { Link } from "react-router-dom";
import Causes from "../../api/cause";

const CauseSection2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div
      className={`wpo-campaign-area section-padding ${props.CmClass}`}
      id="campaign"
    >
      <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <h2 id="causeSectionTitle">Choose coexistence, not extinction</h2>
              <p>
                Restoring Forests to Secure a Future for Leopards: Join the
                Campaign
              </p>
            </div>
          </div>
        </div>
        <div className="wpo-campaign-wrap">
          <div className="row">
            {Causes.slice(3, 6).map((Cause, citem) => (
              <div className="col-lg-4 col-md-6 col-12" key={citem}>
                <div className="campaign_hover">
                  <Link onClick={ClickHandler} to={`/cause-single/${Cause.id}`}>
                    <div className="wpo-campaign-single">
                      <div className="wpo-campaign-item">
                        <div className="wpo-campaign-img">
                          <img src={Cause.cImg} alt="" />
                        </div>
                        <div className="wpo-campaign-content">
                          <div className="wpo-campaign-text-top">
                            <h2>{Cause.cTitle}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CauseSection2;
