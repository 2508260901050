import React, { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";
import Image from "../../images/about-title.webp";

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      {/* <PageTitle
        className="about-page-background"
        pageTitle={"About Us"}
        pagesub={"About"}
      /> */}
      <PageTitle Image={Image} Title="About Us" />

      <Row>
        <Col sm="12">
          <div className="wpo-donation-page-area section-padding">
            {/* // className="wpo-case-text-top"
              // style={{ margin: "15px", padding: "15px" }} */}
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <div className="donation-content">
                <p>
                  Welcome to Pardos Conservation Charitable Trust, where our
                  commitment to the preservation of wildlife and the natural
                  world is at the heart of everything we do.
                </p>
                <p>
                  Our mission is simple yet profound: to safeguard and protect
                  our planet's precious biodiversity. We firmly believe that
                  every species, from the smallest insects to the most majestic
                  mammals, plays a vital role in the intricate web of life on
                  Earth. Through dedicated conservation efforts, we strive to
                  ensure that this rich tapestry endures for generations to
                  come.
                </p>
                <p>
                  At Pardos Conservation, we envision a world where humans and
                  wildlife coexist harmoniously, where nature's wonders are
                  cherished, and where every ecosystem thrives. Our vision
                  extends beyond the immediate present, as we work diligently to
                  create a sustainable future where the planet's natural
                  treasuresare preserved in all their glory.
                </p>
              </div>
              <div className="wpo-about-text">
                <h3>Discover Pardos Conservation</h3>
                <p>
                  Our team comprises dedicated experts with years of experience
                  in wildlife conservation, ecology, and environmental science.
                </p>
                <ul>
                  <li>
                    Our team comprises dedicated experts with years of
                    experience in wildlife conservation, ecology, and
                    environmental science.
                  </li>
                  <li>
                    We take a holistic approach to conservation, addressing not
                    only the protection of species but also the preservation of
                    their habitats and the well-being of local communities.
                  </li>
                  <li>
                    We are committed to transparency and accountability in all
                    our actions. Your support directly fuels our conservation
                    efforts.
                  </li>
                  <li>
                    We actively seek partnerships with local communities, fellow
                    conservation organizations, and governmental agencies to
                    maximize our impact.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
